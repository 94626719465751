<template>
  <v-container
    fluid
    class="container-bg rounded elevation-3 pa-6">
    <table>
      <tbody>
        <tr>
          <td class="pr-4">
            <h2>PO Description</h2>
          </td>
        </tr>
        <tr>
          <td class="pr-4">
            PO No :
          </td>
          <td> {{ purchaseOrderInfo.orderNo }}</td>
        </tr>
        <tr>
          <td class="pr-4">
            Received Orders :
          </td>
          <td>
            <span
              v-if="purchaseOrderInfo.receivedQuantity === purchaseOrderInfo.totalQuantity"
              class="green--text">
              {{ purchaseOrderInfo.receivedText }}
            </span>
            <span
              v-else-if="purchaseOrderInfo.receivedQuantity && purchaseOrderInfo.totalQuantity"
              class="orange--text">
              {{ purchaseOrderInfo.receivedText }}
            </span>
            <span
              v-else-if="!purchaseOrderInfo.receivedQuantity"
              class="red--text">
              {{ purchaseOrderInfo.receivedText }}
            </span>
          </td>
        </tr>
        <tr class="pr-4">
          <td>
            <p class="text-bold mt-4">
              ผู้ขาย
            </p>
          </td>
        </tr>
        <tr>
          <td class="pr-4">
            ชื่อผู้ขาย / Vendor Name :
          </td>
          <td>
            {{ senderInfo.name }}
          </td>
        </tr>
        <tr>
          <td class="pr-4">
            สาขา / Branch :
          </td>
          <td>
            {{ senderInfo.branch }}
          </td>
        </tr>
        <tr>
          <td class="pr-4">
            ผู้ติดต่อ / Contact Person :
          </td>
          <td>
            {{ senderInfo.contactName || 'ไม่ระบุ' }}
          </td>
        </tr>
        <tr>
          <td class="pr-4">
            เบอร์ติดต่อ / Tel :
          </td>
          <td>
            {{ senderInfo.phoneNumber || 'ไม่ระบุ' }}
          </td>
        </tr>
        <tr>
          <td class="pr-4">
            E-mail :
          </td>
          <td>
            {{ senderInfo.email || 'ไม่ระบุ' }}
          </td>
        </tr>
      </tbody>
    </table>

    <v-row
      justify-md="space-between"
      class="mt-8">
      <v-col
        cols="12"
        class="d-flex justify-end mb-6">
        <v-btn
          color="primary"
          elevation="2"
          @click="onCreateDelivery"
        >
          สร้างใบนำเข้า
        </v-btn>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols="9">
                รายการใบนําสินค้าเข้า
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="searchDeliveryNote"
                  append-icon="mdi-magnify"
                  outlined
                  dense
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            hide-default-footer
            :headers="deliveryuNoteHeaders"
            :search="searchDeliveryNote"
            :items="deliverNotes"
            :items-per-page="-1"
            class="elevation-1"
          >
            <template #[`item.no`]="{ item, index }">
              {{ index + 1 }}
            </template>
            <template #[`item.quantity`]="{ item }">
              <span
                v-if="item.receivedQty === item.quantity"
                class="green--text">
                {{ item.receivedQty }} / {{ item.quantity }}
              </span>
              <span
                v-else-if="item.receivedQty && item.quantity"
                class="orange--text"
              >
                {{ item.receivedQty }} / {{ item.quantity }}
              </span>
              <span
                v-else-if="!item.receivedQty"
                class="red--text"
              >
                {{ item.receivedQty }} / {{ item.quantity }}
              </span>
            </template>
            <template #[`item.action`]="{ item }">
              <v-btn
                color="secondary"
                small
                icon
                @click="openNewTab({
                  name: 'DeliveryNote',
                  query: {
                    id: item.id,
                    warehouse: item.warehouse.id,
                    poNo: item.purchaseOrderId || null
                  }
                })"
              >
                <v-icon small>
                  mdi-magnify
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      justify-md="space-between"
      class="mt-8">
      <v-col
        cols="12">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols="9">
                รายการออเดอร์สินค้า
                <v-btn
                  tonal
                  class="ml-5"
                  @click="gotoPrintOrdersPage()"
                >
                  <v-icon>
                    mdi-printer
                  </v-icon>
                  <span>PRINT</span>
                </v-btn>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="searchOrder"
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            hide-default-footer
            :headers="orderHeaders"
            :search="searchOrder"
            :items="purchaseOrderInfo.orders"
            :items-per-page="-1"
            class="elevation-1"
          >
            <template #[`item.no`]="{ item, index }">
              {{ index + 1 }}
            </template>
            <template #[`item.quantity`]="{ item }">
              <span
                v-if="item.receivedQty === item.quantity"
                class="green--text">
                {{ item.receivedQty }} / {{ item.quantity }}
              </span>
              <span
                v-else-if="item.receivedQty && item.quantity"
                class="orange--text"
              >
                {{ item.receivedQty }} / {{ item.quantity }}
              </span>
              <span
                v-else-if="!item.receivedQty"
                class="red--text"
              >
                {{ item.receivedQty }} / {{ item.quantity }}
              </span>
            </template>
            <template #[`item.size`]="{ item }">
              <span>
                {{ item.color.toUpperCase() }} - {{ item.size.toUpperCase() }}
              </span>
            </template>
            <template #[`item.action`]="{}">
              <v-btn
                color="secondary"
                small
                icon
              >
                <v-icon small>
                  mdi-magnify
                </v-icon>
              </v-btn>
            </template>
            <template #[`item.imageURL`]="{ item }">
              <img
                :src="`${item.imageURL ? `${item.imageURL}?w=100` : noPicture}`"
                width="100px"
                alt=""
                set=""
                class="pa-2"
                @click="previeImage(item.imageURL)"
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <img-modal-preview
      v-model="previewEnabled"
      :img-src="currentImgPreview" />
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import PurchaseOrdersProvider from '@/resources/PurchaseOrdersProvider'
import InventoryProvider from '@/resources/InventoryProvider'
import WarehouseProvider from '@/resources/WarehouseProvider'
import ImgModalPreview from '@/components/ImgModalPreview'
import NoPicture from '@/assets/image/no_picture_available.png'

const PurchaseOrdersService = new PurchaseOrdersProvider()
const InventoryService = new InventoryProvider()
const WarehouseService = new WarehouseProvider()

export default {
  components: {
    ImgModalPreview
  },
  data () {
    return {
      previewEnabled: false,
      currentImgPreview: null,
      purchaseOrderInfo: {},
      deliverNotes: [],
      warehouses: [],
      searchOrder: '',
      searchDeliveryNote: '',
      orderHeaders: [
        { text: 'ลำดับ', value: 'no', align: 'center' },
        { text: 'รูปสินค้า', value: 'imageURL' },
        { text: 'แบรนด์', value: 'brand' },
        { text: 'คอลเลคชั่น', value: 'gwCollection' },
        { text: 'รหัสสินค้า', value: 'itemNo' },
        { text: 'size / color', value: 'size' },
        { text: 'รายการ', value: 'description' },
        { text: 'วันที่จัดส่ง', value: 'expectedReceiptDate' },
        { text: 'จำนวนทั้งหมด', value: 'quantity', align: 'center' }
      ],
      deliveryuNoteHeaders: [
        { text: 'ลำดับ', value: 'no', align: 'center' },
        { text: 'เลขที่', value: 'code' },
        { text: 'คลังสินค้า', value: 'warehouse.name' },
        { text: 'รหัสคลังสินค้า', value: 'warehouse.code' },
        { text: 'สถานะ', value: 'status' },
        { text: 'ตัวเลือก', value: 'action' }
      ],
      noPicture: NoPicture
    }
  },
  computed: {
    senderInfo () {
      const defaultEmptyInfo = {
        name: 'ไม่ระบุ',
        branch: 'ไม่ระบุ',
        contactName: 'ไม่ระบุ',
        phoneNumber: 'ไม่ระบุ',
        email: 'ไม่ระบุ'
      }
      return this.purchaseOrderInfo.sender ? this.purchaseOrderInfo.sender : defaultEmptyInfo
    }
  },
  async mounted () {
    await Promise.all([this.getOverviewByOrderNo(), this.getAllWarehouses()])
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal'
    }),
    async getAllWarehouses () {
      try {
        this.loading = true

        const { data } = await WarehouseService.getWarehouse()
        this.warehouses = data
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async getOverviewByOrderNo () {
      try {
        this.loading = true
        const { data } = await PurchaseOrdersService.getOverviewByOrderNo(this.$route.params.orderNo)
        this.purchaseOrderInfo = data
        this.getDeliveryNoteCodes(this.purchaseOrderInfo.deliveryNoteIds)
      } catch (error) {
        console.error('getItems', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async getDeliveryNoteCodes (deliveryNoteIds) {
        const { data } = await InventoryService.getDeliveryNoteCodes({ ids: deliveryNoteIds })
        this.deliverNotes = data
    },
    async previeImage (imageURL) {
        this.previewEnabled = true
        this.currentImgPreview = imageURL || this.noPicture
    },
    openNewTab (routeInfo) {
        const routeData = this.$router.resolve({ name: routeInfo.name, query: routeInfo.query })
        window.open(routeData.href, '_blank')
    },
    onCreateDelivery () {
      const warehouse = this.warehouses.find((w) => w.code === this.purchaseOrderInfo.locationCode)
      this.setModal({
        value: true,
        title: 'Create delivery note',
        message: 'Do you want to create delivery note from this purchase order',
        confirmText: 'Sure',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => {
          this.openNewTab({
            name: 'DeliveryNote',
            query: {
              poNo: this.purchaseOrderInfo.orderNo,
              warehouse: warehouse.id
            }
          })
        }
      })
    },
    gotoPrintOrdersPage () {
      this.openNewTab({ name: 'PoOrders', params: { orderNo: this.purchaseOrderInfo.orderNo } })
    }
  }
}
</script>

<style scoped>
.container-bg {
  background-color: white;
}
.content {
  position: absolute;
  top: -9999px;
  padding: 40px;
  background-color: #fff;
}

</style>
